<template>
  <v-container
    :style="$vuetify.breakpoint.mdOnly ? 'max-width:1000px' : ''"
    class="pb-0 px-0"
  >
    <base-material-card style="padding-bottom: 0 !important">
      <v-select
        class="my-4"
        v-if="$vuetify.breakpoint.xsOnly"
        :items="tabs"
        item-text="text"
        item-value="href"
        v-model="tab"
        outlined
        dense
        style="height: 20px"
      ></v-select>
      <v-row
        ><v-tabs
          v-if="!$vuetify.breakpoint.xsOnly"
          v-model="tab"
          right
          class="pl-0 pb-0"
          style="border-color: white; border-bottom: -2px; border-width: 1px"
        >
          <v-tab
            href="#sales"
            class="pl-0 ml-0 pr-0"
            active-class="color"
            style="font-size: 14px"
            >{{ $t("stats.sales") }}</v-tab
          >
          <v-tab href="#clients" active-class="color" style="font-size: 14px">{{
            $t("stats.clients")
          }}</v-tab>

          <v-tab
            href="#appointments"
            active-class="color"
            style="font-size: 14px"
            >{{ $t("stats.appointments") }}</v-tab
          >
          <v-tab
            href="#expenses"
            active-class="color"
            style="font-size: 14px"
            >{{ $t("stats.expenses") }}</v-tab
          >

          <v-row>
            <v-col style="margin-top: -24px" cols="12">
              <MonthSelector />
            </v-col>
          </v-row>
        </v-tabs>
      </v-row>

      <v-tabs-items
        v-model="tab"
        style="border-color: red; border-width: medium"
      >
        <v-tab-item value="sales">
          <SalesStats height="110px" />
        </v-tab-item>
        <v-tab-item value="clients">
          <ClientsStats :title="$t('total')" height="110px" />
        </v-tab-item>
        <v-tab-item value="appointments">
          <AppointmentsStats :title="$t('total')" height="110px" />
        </v-tab-item>
        <v-tab-item value="expenses">
          <ExpensesStats :title="$t('total')" height="110px" />
        </v-tab-item>
      </v-tabs-items>
    </base-material-card>
  </v-container>
</template>

<script>
export default {
  name: "Stats",
  components: {
    ExpensesTable: () => import("@/components/expense/NewTable"),
    SalesStats: () => import("@/components/stats/sales/SalesStats"),
    ClientsStats: () => import("@/components/stats/clients/ClientsStats"),
    AppointmentsStats: () =>
      import("@/components/stats/appointments/AppointmentsStats"),
    ExpensesStats: () => import("@/components/stats/expenses/ExpensesStats"),
    MonthSelector: () => import("@/components/stats/MonthSelector"),
  },
  data() {
    return {
      tab: "sales",
      menu: {
        month: false,
        day: false,
        week: false,
      },
    };
  },

  methods: {},
};
</script>

<style></style>
